@import '../../../../scss/theme-bootstrap';

.content-block-video {
  position: relative;
  &__close {
    text-align: #{$rdirection};
  }
  &__container {
    position: relative;
  }
  &__reveal {
    display: none;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    .content-block-video.active & {
      display: block;
      opacity: 1;
      height: auto;
      iframe {
        height: 100%;
        top: 0;
        #{$ldirection}: 0;
      }
    }
    .content-block-video.active.complete & {
      position: static;
      height: auto;
    }
  }
  video {
    display: block;
    width: 100%;
    height: auto;
  }
  &__landing {
    position: relative;
    transition: opacity 0.3s;
    cursor: pointer;
    .content-block-video.active & {
      z-index: 0;
      opacity: 0;
      top: 0;
      #{$ldirection}: 0;
    }
    .content-block-video.active.complete & {
      position: absolute;
      z-index: -1;
    }
  }
  &__play {
    @include icon('play');
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
    font-size: 40px;
  }
  &__image {
    display: block;
    width: 100%;
  }
}

// Colorbox overlay
.overlay--content-block-video {
  &#colorbox {
    border: none;
    overflow: visible !important;
  }
  &#cboxOverlay {
    background: rgba($color-black, 0.9);
    border: none;
    overflow: visible;
  }
  #cboxClose {
    background: transparent;
    font-size: 32px;
    width: 32px;
    height: 32px;
    top: 12px;
    @include breakpoint($medium-up) {
      top: -40px;
      #{$rdirection}: 0;
    }
  }
  #cboxWrapper {
    background: transparent;
    max-width: 100%;
    overflow: visible;
  }
  #cboxContent {
    margin: 0;
    background: transparent;
    max-width: 100%;
    padding: 0;
    overflow: visible;
  }
  #cboxLoadedContent {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0;
    background: $color-black;
    @include breakpoint($medium-up) {
      background: transparent;
    }
    iframe,
    video,
    object {
      display: block;
      width: 100%;
      height: 100%;
    }
    video {
      height: auto;
    }
  }
}
